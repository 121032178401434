import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import TopContainer from "../components/TopContainer"

import { Group, Col, Section, CtaButton } from "../components/common"

import img from "../images/stock/solution.jpg"

import imgSolution from "../images/s-factor/solution.png"

import circles from "../images/s-factor/circles.png"
import mspi2 from "../images/s-factor/mspi2.png"
import boxes2 from "../images/s-factor/boxes2.png"
import features from "../images/s-factor/features.png"

import imgMoneyPlant from "../images/moneyplant.jpg"
import imgTaxonomyHighlights from "../images/taxonomy-highlights.gif"
import imgHowItWorks from "../images/how-it-works.gif"
import imgFunnel from "../images/funnel.svg"
import imgIntegration from "../images/integration.gif"
import imgProduct from "../images/product2.png"

import iconDataAnalytics from "../images/icons/data-analytics.svg"
import iconRatings from "../images/icons/ratings.svg"
import iconDigital from "../images/icons/digital.svg"
import iconSubscriptions from "../images/icons/subscriptions.svg"

import imgScore from "../images/score.svg"
import imgIndex from "../images/index.svg"
import imgRating from "../images/rating.svg"
import { ProductDiagram } from "../components/diagrams/ProductDiagram"

class SFactorPage extends Component {
  state = {
    blockData: [
      {
        title: "11,000+",
        subtitle: "Companies",
        color: "#F05E2A",
      },
      {
        title: "74",
        subtitle: "Social Impact Themes",
        color: "#F37043",
      },
      {
        title: "45",
        subtitle: "Frameworks",
        color: "#F37F58",
      },
      {
        title: "300+",
        subtitle: "Social specific indicators & thousands of metrics",
        color: "#F6906E",
      },
    ],
  }

  render() {
    return (
      <>
        <Layout>
          <SEO
            title="Social Impact Data | Data Analytics Companies"
            keywords={["Social Impact Data", "Data Analytics Companies"]}
            description="We offer the deepest set of social impact data in the market which has made us one of the best data analytics companies around. Get in touch to learn more."
          />
          <div className="page page-s-factor">
            <TopContainer
              titleLines={["Solution"]}
              backgroundImage={img}
              // body="Sustainable, Responsible and Impact investing (SRI) - an investment discipline which considers environmental, social and corporate governance (ESG) criteria to generate long-term competitive financial returns."
              body="The nucleus of the S Factor model measures individual companies’ socio-economic, socio-cultural and socio-political impact on people, starting with its employees, and incorporating the systemic impact in the communities where it does business."
              // ctaText="Learn More"
              // ctaLinkTo={"/about"}
              isVideo={true}
              mp4="https://firebasestorage.googleapis.com/v0/b/sfactor-website.appspot.com/o/solution.mp4?alt=media"
            />

            <Section>
              <Group>
                <Col size="two-thirds">
                  <p>
                    The S-Factor Solution provides the deepest set of social
                    impact data currently available on the market and delivers
                    it in a platform that is completely customizable to suit
                    clients’ needs.
                  </p>
                </Col>
              </Group>
            </Section>

            <Section>
              <Group>
                <Col size="third">
                  <div className="headingAccent" />

                  <h3>Product</h3>
                </Col>
                <Col size="two-thirds">
                  {/* <ProductDiagram /> */}
                  <img src={imgProduct} width={"90%"} />
                  <div className="product-icons">
                    <Group>
                      <Col size="quarter">
                        <img src={iconDataAnalytics} height="54" />
                        <p>Data Analytics for Social Impact and Returns</p>
                      </Col>
                      <Col size="quarter">
                        <img src={iconRatings} height="54" />
                        <p>Company Ratings, Rankings and Index Product</p>
                      </Col>
                      <Col size="quarter">
                        <img src={iconDigital} height="54" />
                        <p>
                          Digital, Mobile, Web App, Dashboard & Custom Reports
                        </p>
                      </Col>
                      <Col size="quarter">
                        <img src={iconSubscriptions} height="54" />
                        <p>Subscription, License, Royalties, Custom</p>
                      </Col>
                    </Group>
                  </div>
                </Col>
              </Group>
            </Section>

            <Section>
              <Group>
                <Col size="third">
                  <div className="headingAccent" />

                  <h3>Method</h3>
                </Col>
                <Col size="two-thirds">
                  <p>
                    Highlights from our comprehensive taxonomy screen for the
                    largest number of social criteria across the entire ESG
                    market - structured by 6 categories consisting of 74 social
                    impact themes, hundreds of indicators, and thousands of
                    metrics. Our performance data provides deep-analytics in
                    relation to hundreds of possible queries.
                  </p>
                </Col>
                <Col size="whole">
                  <img src={imgTaxonomyHighlights} width={"100%"} />
                </Col>
              </Group>
            </Section>

            <Section>
              <Group>
                <Col size="third">
                  <div className="headingAccent" />

                  <h3>Process</h3>
                </Col>
                <Col size="two-thirds">
                  <p>
                    When comparing ESG products - methods, metrics, indicators,
                    coverage, relevancy, materiality and taxonomy are their most
                    critical components.
                  </p>
                  <p>
                    The process of compiling hundreds of layers, from sometimes
                    unstructured sources can be daunting.
                  </p>
                  <p>This is our simplified process:</p>
                </Col>
                <Col size="whole">
                  <div style={{ height: 8 }} />
                  <img src={imgFunnel} width={"100%"} />
                </Col>
              </Group>
            </Section>

            <Section>
              <Group>
                <Col size="quarter">
                  <div className="headingAccent" />
                  <h3>Results</h3>
                </Col>
                <Col size="three-quarters">
                  <Group>
                    <Col size="quarter">
                      <img src={imgScore} width={120} />
                      <div style={{ height: 20 }} />
                    </Col>
                    <Col size="three-quarters">
                      <h4>
                        The S-Factor Score<sup>TM</sup>
                      </h4>
                      <p>
                        The S-Factor Score qualifies material evidence from
                        validated sources across dozens of regulations, codes,
                        frameworks, and benchmarks and provides an aggregate
                        score for each company based on their performance of
                        more than 300 social impact indicators, and thousands of
                        metrics.
                      </p>
                      <p></p>
                    </Col>
                  </Group>

                  <div style={{ height: 20 }}></div>

                  <Group>
                    <Col size="quarter">
                      <img src={imgIndex} width={120} />
                      <div style={{ height: 20 }} />
                    </Col>

                    <Col size="three-quarters">
                      <h4>
                        The Social Performance Index (MSPI<sup>TM</sup>)
                      </h4>
                      <p>
                        The MSPI features companies with a high S-Factor Score,
                        positive public sentiment and superior financial
                        returns, representing the best in class in both social
                        impact and financial performance.
                      </p>
                      <p></p>
                    </Col>
                  </Group>

                  <div style={{ height: 20 }}></div>

                  <Group>
                    <Col size="quarter">
                      <img src={imgRating} width={120} />
                      <div style={{ height: 20 }} />
                    </Col>

                    <Col size="three-quarters">
                      <h4>
                        The S-Factor Rating<sup>TM</sup>
                      </h4>
                      <p>
                        The S-Factor Rating uses information created by the
                        S-Factor modelling to provide real time risk ratings as
                        it relates to social issues, the probability of
                        disruption and the level of preparedness to manage
                        uncertainties.
                      </p>
                      <p></p>
                    </Col>
                  </Group>
                </Col>
              </Group>
            </Section>

            <Section>
              <Group>
                <Col size="third">
                  <div className="headingAccent" />

                  <h3>Value</h3>
                </Col>
                <Col size="two-thirds">
                  <p>
                    Subject expertise and the uniqueness of our analytics
                    provide quick reference formulations for value added
                    information. By including the depth of social impact
                    qualification and quantification, companies and investors
                    can clearly understand their social risk operating
                    environments and monitor changes to these variables in
                    real-time.
                  </p>
                  <p>
                    <CtaButton
                      href="https://firebasestorage.googleapis.com/v0/b/sfactor-website.appspot.com/o/S-Factor-FAQs-2.13.2020.pdf?alt=media"
                      dark
                      target="_blank"
                    >
                      Frequently Asked Questions
                    </CtaButton>
                  </p>
                </Col>
              </Group>
            </Section>

            <Section hasDivider={false}>
              <Group>
                <Col size="third">
                  <div className="headingAccent" />

                  <h3>Performance</h3>
                </Col>
                <Col size="two-thirds">
                  <p></p>
                  <CtaButton linkTo={"analytics"} dark={true}>
                    Learn More
                  </CtaButton>
                </Col>
              </Group>
            </Section>
            <div style={{ height: 20 }}></div>
          </div>
        </Layout>
      </>
    )
  }
}

export default SFactorPage
