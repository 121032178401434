import React from "react"

import img1 from "../../images/product-icons/product-icons-analytics.gif"
import img2 from "../../images/product-icons/product-icons-ratings.gif"
import img3 from "../../images/product-icons/product-icons-apps.gif"
import img4 from "../../images/product-icons/product-icons-subscription.gif"

class ProductDiagram extends React.Component {
  items = [
    {
      img: img1,
      text: "Data Analytics for Social Impact and Returns",
    },
    {
      img: img2,
      text: "Company Ratings, Rankings and Index Product",
    },
    {
      img: img3,
      text: "Digital, Mobile, Web App, Dashboard & Custom Reports",
    },
    {
      img: img4,
      text: "Subscription, License, Royalties, Custom",
    },
  ]

  renderItems() {
    return this.items.map(item => {
      return (
        <div className="item">
          <img src={item.img}></img>
          <hr />
          <p>{item.text}</p>
        </div>
      )
    })
  }

  render() {
    return <div className="product-diagram">{this.renderItems()}</div>
  }
}

export { ProductDiagram }
